<template>
  <div>
    <advertisment-section />
    <b-row>
      <b-col lg="12" class="pb-3 pt-2">
        <div class="d-flex justify-content-center col-12 text-center">
          <h2>Scanned Results</h2>
        </div>
        <score-table />
      </b-col>
      <b-col lg="12">
        <div class="d-flex justify-content-center col-12 text-center pb-2">
          <h2>Recently scanned</h2>
        </div>
        <b-card no-body class="card-company-table">
          <scan-result-table :items_data="scaned_data" @detailView="viewDetails($event)" />
          <div class="d-flex justify-content-center col-12 text-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="number_of_pages"
              size="sm"
              first-number
              last-number
            />
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" class="mb-2">
        <div class="d-flex justify-content-center col-12 text-center">
          <iframe
            v-if="is_mobilesize"
            data-aa="1723658"
            src="//ad.a-ads.com/1723658?size=300x250"
            style="
              width: 300px;
              height: 250px;
              border: 0px;
              padding: 0;
              overflow: hidden;
              background-color: transparent;
            "
          ></iframe>
          <iframe
            v-if="!is_mobilesize"
            data-aa="1723659"
            src="//ad.a-ads.com/1723659?size=728x90"
            style="
              width: 728px;
              height: 90px;
              border: 0px;
              padding: 0;
              overflow: hidden;
              background-color: transparent;
            "
          ></iframe>
        </div>
      </b-col>
      <b-col lg="12" class="mb-2">
        <b-list-group>
          <b-list-group-item class="d-flex">
            <span class="mr-1" style="font-size: 20px"> 🤩 </span>
            <span
              >All main functions have been implemented and the contract looks
              good. DYOR before investing.</span
            >
          </b-list-group-item>

          <b-list-group-item class="d-flex">
            <span class="mr-1" style="font-size: 20px"> 🙂 </span>
            <span
              >The contract doesn't have some main safety functions implemented.
              DYOR before investing.</span
            >
          </b-list-group-item>

          <b-list-group-item class="d-flex">
            <span class="mr-1" style="font-size: 20px"> 😕 </span>
            <span
              >The contract doesn't have main safety functions implemented, and
              this is likely a rug pull. DYOR before investing.</span
            >
          </b-list-group-item>

          <b-list-group-item class="d-flex">
            <span class="mr-1" style="font-size: 20px"> 😱 </span>
            <span
              >The contract cannot be read and there's a high chance of getting
              rugged. DYOR before investing.</span
            >
          </b-list-group-item>

          <b-list-group-item class="d-flex">
            <span class="mr-1" style="font-size: 20px"> 🤐 </span>
            <span
              >Something went wrong in the scanner system. Please try
              again.</span
            >
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <advertisment-section />
  </div>
</template>

<script>
// import ResultDialog from './ResultDialog.vue'
import { mixinList } from "@/mixins/mixinList";
import {
  BCard,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BPagination,
} from "bootstrap-vue";
import AdvertismentSection from "@core/layouts/components/AdvertismentSection.vue";
import ScanResultTable from "./ScanResultTable.vue";
import ScoreTable from "./ScoreTable.vue";

export default {
  mixins: [mixinList],
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    AdvertismentSection,
    BPagination,
    ScanResultTable,
    ScoreTable
  },
  data() {
    return {
      currentPage: 1,
      rows: 70,
      
    };
  },
  watch: {
    currentPage(val) {
      this.currentPage = val;
      this.fetchData();
    },
  },
  computed: {
    scaned_data() {
      return this.$store.state.scan.scaned_data;
    },
    number_of_pages() {
      return this.$store.state.scan.number_of_pages;
    },
  },
  methods: {
    fetchData() {
      this.$store.dispatch("FETCH_SCANED_RESULTS", this.currentPage);
    },
    viewDetails(data) {
      this.$router.push({
        name: "scan-result",
        params: {
          id: data.contract_address,
          came_from: "scan-result-page",
        },
      });
    },
  },
  created() {
    this.fetchData();
    
  },
};
</script>

<style>
@media (max-width: 1024px) {
  .scan-result-list-table {
    font-size: 10px;
    line-height: 25px;
  }
}
.scan-result-list-table td {
  padding-left: 10px !important;
}

.scan-result-list-table th {
  padding: 0px 5px 0px 5px !important;
}

.scan-result-list-table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scan-result-list-table::-webkit-scrollbar {
  display: none;
}
</style>
