<template>
  <div>
    <b-table
      striped
      hover
      responsive
      text-nowrap
      class="scan-result-list-table"
      :items="items_data"
      :fields="!is_mobilesize ? fields : mob_fields"
      @row-clicked="detailView"
    >
      <template #cell(token_symbol)="data">
        {{
          data.value
            ? !is_mobilesize
              ? data.value
              : data.value.slice(0, 7) + ".."
            : "N/A"
        }}
      </template>

      <template #cell(token_name)="data">
        {{ data.value ? data.value : "N/A" }}
      </template>

      <template #cell(final_verdict_by_emoji)="data">
        <div
          class="text-center"
          :style="is_mobilesize ? 'font-size: 15px' : 'font-size: 25px'"
        >
          {{ data.value }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mixinList } from "@/mixins/mixinList";
import { BTable } from "bootstrap-vue";
export default {
  props: ["items_data"],
  mixins: [mixinList],
  components: {
    BTable,
  },
  data() {
    return {
      mob_fields: [
        {
          key: "token_name",
          label: "Name",
        },
        {
          key: "confident_score",
          label: "score",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "last_scanned",
          label: "Scanned",
        },
        {
          key: "final_verdict_by_emoji",
          label: "Final Verdict",
          thClass: "text-center",
        },
      ],
      fields: [
        {
          key: "token_symbol",
          label: "Symbol",
        },
        {
          key: "token_name",
          label: "Name",
        },
        {
          key: "confident_score",
          label: "confidence score",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "last_scanned",
          label: "Scanned",
        },
        {
          key: "final_verdict_by_emoji",
          label: "Final Verdict",
          thClass: "text-center",
        },
      ],
    };
  },
  methods: {
    detailView(value) {
      this.$emit("detailView", value);
    },
  },
};
</script>