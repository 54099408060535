<template>
  <div class="pt-2">
    <b-tabs content-class="mt-1">
      
      <b-tab :title="!is_mobilesize ? 'Highest Scores':'Highest'">
        <b-row class="match-height mt-1">
          <b-col lg="12">
            <scan-score-table
              :items_data="high_score_data"
              @detailView="viewDetails($event)"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="!is_mobilesize ? 'Lowest Scores' : 'Lowest'">
        <b-row class="match-height mt-1">
          <b-col lg="12">
            <scan-score-table
              :items_data="lower_score_data"
              @detailView="viewDetails($event)"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Frequently Scanned">
        <b-row class="match-height mt-1">
          <b-col lg="12">
            <scan-score-table
              :items_data="most_scan_data"
              @detailView="viewDetails($event)"
            />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BRow, BCol, BTabs, BTab } from "bootstrap-vue";
import ScanScoreTable from "./ScanScoreTable.vue";
import { mixinList } from "@/mixins/mixinList";

export default {
  mixins: [mixinList],
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    ScanScoreTable
  },
  data() {
    return {
      tokenHolders: [],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    high_score_data() {
      return this.$store.state.scan.high_score_data;
    },
    lower_score_data() {
      return this.$store.state.scan.lower_score_data;
    },
    most_scan_data() {
      return this.$store.state.scan.most_scan_data;
    },
  },
  methods: {
    fetchData() {
      this.$store.dispatch("FETCH_ANALYTICS_RESULTS");
    },
    viewDetails(data) {
      this.$router.push({
        name: "scan-result",
        params: {
          id: data.contract_address,
          came_from: "scan-result-page",
        },
      });
    },
  },
};
</script>

<style>
.scan-result-table td {
  padding-left: 10px !important;
}

.scan-result-table th {
  padding: 0px 5px 0px 5px !important;
}

.scan-result-table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scan-result-table::-webkit-scrollbar {
  display: none;
}
.percentage-col {
  padding-right: 5px !important;
}
</style>
